//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { scrollInit } from '../utils/scroll';
import { mapState } from 'vuex';
export default {
  name: "portfolio",

  data() {
    return {
      apiTitle: this.$api.api_title + 'images/',
      topPicture: {},
      dialogVisible: false,
      Portfolio_list: [],
      ruleForm: {},
      linguisticType: localStorage.getItem('linguisticType'),
      itemHeight: '',
      nowbodyWidth: ''
    };
  },

  watch: {
    $route(to, from) {
      // this.scrollInit()
      scrollInit(this.$route.query.hash);
    },

    bodyWidth(newValue, oldValue) {
      if (oldValue) {
        console.log('bodyWidth', this.bodyWidth);
        var x = document.getElementsByClassName('portfolio-item');
        setTimeout(() => {
          this.itemHeight = x[0].clientWidth;
        }, 50);
        console.log('itemHeight', this.itemHeight);
      }
    }

  },
  computed: { ...mapState({
      device: state => state.device,
      bodyWidth: state => state.bodyWidth
    })
  },

  created() {
    this.getList();
    this.getImg();
  },

  mounted() {
    if (this.$route.query.hash) {
      scrollInit(this.$route.query.hash);
    }

    var x = document.getElementsByClassName('portfolio-item');
    setTimeout(() => {
      this.itemHeight = x[0].clientWidth;
    }, 50); // window.onresize = () => {
    //     return (()=>{
    //         this.bodyWidth = document.body.clientWidth
    //     })()
    // }

    this.nowbodyWidth = this.bodyWidth ? this.bodyWidth : document.body.clientWidth;
  },

  methods: {
    eover(e, i) {
      // debugger
      this.Portfolio_list[i].isEover = true;
      this.Portfolio_list = [].concat(this.Portfolio_list); // this.$refs['img' + i][0].src = e.mouseGlideImage
      // console.log('进入',this.$refs['img' + i][0].src)
    },

    eleave(e, i) {
      this.Portfolio_list[i].isEover = false;
      this.Portfolio_list = [].concat(this.Portfolio_list); // this.$refs['img' + i][0].src = e.bgLucencyImage
    },

    checkDetial(e) {
      this.ruleForm = e;
      console.log(this.ruleForm);
      this.dialogVisible = true;
    },

    toBusiness(e) {
      // window.location.href = 'https://www.baidu.com/'
      window.open(e);
    },

    getImg() {
      this.$http.get(this.$api.listTopPicture, {
        params: {
          pictureType: 4
        }
      }).then(res => {
        if (res.code == 0) {
          if (res.data.rows.length > 0) {
            this.topPicture = res.data.rows[0];
          }
        }
      });
    },

    entryOfficial(e) {
      window.open(e);
    },

    objectSpanMethod({
      row,
      column,
      rowIndex,
      columnIndex
    }) {
      // console.log('row',row)
      // if(row.rowspan != 0){
      //     if(row.colspan != 0){
      //         return {
      //             rowspan: row.rowspan,
      //             colspan: row.colspan
      //         }
      //     } else {
      //         return {
      //             rowspan: row.rowspan,
      //             colspan: 0
      //         }
      //     }
      // } else {
      //     if(row.colspan != 0){
      //         return {
      //             rowspan: 0,
      //             colspan: row.colspan,
      //         }
      //     } else {
      //         return {
      //             rowspan: 0,
      //             colspan: 0
      //         }
      //     }
      // }
      //先判断合并列为0 的情况
      if (row.colspan === 0) {
        // 合并0列 合并2行
        if (row.rowspan === 2) {
          console.log('当前行', row);
          return {
            rowspan: 2,
            colspan: 0
          };
        } // 合并0列 合并0行
        // if (row.rowspan === 0) {
        //     return {
        //         rowspan: 0,
        //         colspan: 0
        //     };
        // }

      }
    },

    getList() {
      this.$http.get(this.$api.listPortfolio, {
        params: {
          linguisticType: this.linguisticType,
          pageNum: 1,
          pageSize: 10000
        }
      }).then(res => {
        if (res.code == 0) {
          this.Portfolio_list = res.data.rows;
          this.Portfolio_list.forEach(item => {
            item.isEover = false;
          });
        }
      });
    }

  }
};