var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "introduce content" },
    [
      _c("div", { staticClass: "top-img-box" }, [
        _c("img", {
          staticClass: "top-img",
          attrs: { src: _vm.apiTitle + _vm.topPicture.pictureAddress, alt: "" }
        })
      ]),
      _c("div", { staticClass: "subtitle" }, [
        _vm.linguisticType == 0
          ? _c("span", { staticClass: "span-subtitle" }, [
              _vm._v("我们的被投企业")
            ])
          : _vm._e(),
        _vm.linguisticType == 1
          ? _c("span", { staticClass: "span-subtitle" }, [
              _vm._v("Portfolio Companies")
            ])
          : _vm._e()
      ]),
      _c("section", { staticClass: "introduce-doctor" }, [
        _c(
          "div",
          { staticClass: "portfolio-box" },
          _vm._l(_vm.Portfolio_list, function(v, i) {
            return _c(
              "div",
              {
                key: i,
                staticClass: "portfolio-item",
                class:
                  i % 2 == 0 ? "animated fadeInLeft" : "animated fadeInRight",
                style: { backgroundColor: v.bgColor == "" ? "" : v.bgColor },
                on: {
                  click: function($event) {
                    return _vm.checkDetial(v)
                  }
                }
              },
              [
                _c("img", {
                  attrs: {
                    src:
                      _vm.apiTitle +
                      (v.isEover ? v.mouseGlideImage : v.bgLucencyImage)
                  },
                  on: {
                    mouseover: function($event) {
                      $event.stopPropagation()
                      return _vm.eover(v, i)
                    },
                    mouseleave: function($event) {
                      $event.stopPropagation()
                      return _vm.eleave(v, i)
                    }
                  }
                })
              ]
            )
          }),
          0
        )
      ]),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogVisible, height: "80%" },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c("div", { staticClass: "dialog" }, [
            _c("div", { staticClass: "dialog-header" }, [
              _c("h2", { staticClass: "company-name" }, [
                _vm._v(_vm._s(_vm.ruleForm.companyName))
              ]),
              _c("div", { staticClass: "middle-line" }),
              _vm.ruleForm.tradingPlace != ""
                ? _c("p", { staticClass: "company-address" }, [
                    _vm._v(_vm._s(_vm.ruleForm.tradingPlace))
                  ])
                : _vm._e()
            ]),
            _vm.ruleForm.tradingPlatform != ""
              ? _c("button", { staticClass: "company-industry" }, [
                  _vm._v(_vm._s(_vm.ruleForm.tradingPlatform))
                ])
              : _vm._e(),
            _c("p", { staticClass: "company-describe" }, [
              _vm._v(_vm._s(_vm.ruleForm.intro))
            ]),
            _vm.ruleForm.url != ""
              ? _c("div", { staticClass: "company-enter-box" }, [
                  _vm.linguisticType == 0
                    ? _c(
                        "button",
                        {
                          staticClass: "company-enter",
                          on: {
                            click: function($event) {
                              return _vm.entryOfficial(_vm.ruleForm.url)
                            }
                          }
                        },
                        [_vm._v("进入官网...")]
                      )
                    : _vm._e(),
                  _vm.linguisticType == 1
                    ? _c(
                        "button",
                        {
                          staticClass: "company-enter",
                          on: {
                            click: function($event) {
                              return _vm.entryOfficial(_vm.ruleForm.url)
                            }
                          }
                        },
                        [_vm._v("Enter the official website...")]
                      )
                    : _vm._e()
                ])
              : _vm._e()
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }